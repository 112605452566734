import { gql } from 'graphql-tag'
import queryPageFields from '../../../core/data-layer/pages/page/query-page-fields'
import { fragmentModuleMedia } from '../../../core/data-layer/modules/fragments'
import fragmentFinancialsItem from '../financials/fragment-financials-item'

export default gql`
    ${fragmentModuleMedia}
    ${fragmentFinancialsItem}
    query GetPageInvestorRelationsFinancials($slug: String!, $locale: String!, $preview: Boolean!) {
        page: pageInvestorRelationsFinancialsCollection(limit: 1, where: { slug: $slug }, locale: $locale, preview: $preview) {
            items {
                ${queryPageFields}
                sys {
                    id
                }
                sectionTitlePublications
                titleFinancials
                itemsFinancialsCollection (limit: 10) {
                    items {
                        ... FinancialsItemFragment
                    }
                }
                noteFinancials {
                    json
                }
                titleReport
                itemsReportCollection (limit: 5) {
                    items {
                        ... FinancialsItemFragment
                    }
                }
                noteReport {
                    json
                }
                sectionTitleBusinessUpdates
                titleBusinessUpdates
                itemsBusinessUpdatesCollection (limit: 5) {
                    items {
                        ... FinancialsItemFragment
                    }
                }
                notesBusinessUpdates {
                    json
                }
                sectionTitleDocuments
                titleCapitalMarketsDay
                itemsCapitalMarketsDayCollection (limit: 5) {
                    items {
                        ... FinancialsItemFragment
                    }
                }
                noteCapitalMarketsDay {
                    json
                }
                titleAgm
                itemsAgmCollection (limit: 5) {
                    items {
                        ... FinancialsItemFragment
                    }
                }
                noteAgm {
                    json
                }
                titleEgm
                itemsEgmCollection (limit: 5) {
                    items {
                        ... FinancialsItemFragment
                    }
                }
                noteEgm {
                    json
                }
                titleOther
                itemsOtherCollection (limit: 5) {
                    items {
                        ...ModuleMediaFragment
                    }
                }
            }
        }
    }
`
